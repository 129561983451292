import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import {
  ExercisesWithNotes,
  ExercisesWithNotes_PopularKriyas,
} from './__generated__/ExercisesWithNotes';

import Loading from '../shared/loading';

const EXERCISES_WITH_NOTES_QUERY = gql`
  query ExercisesWithNotes {
    PopularKriyas {
      id
      name
      exerciseRelations {
        number
        Exercise {
          id
          name
          internalNotes
          image {
            id
            url
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
}));

const Notes: React.FC = () => {
  const classes = useStyles();

  const { loading, data, error } = useQuery<ExercisesWithNotes>(
    EXERCISES_WITH_NOTES_QUERY
  );

  const kriyas: ExercisesWithNotes_PopularKriyas[] =
    data && data.PopularKriyas
      ? (data.PopularKriyas as ExercisesWithNotes_PopularKriyas[])
      : [];

  const withNotes = kriyas
    .map((kriya) => {
      const newKriya = {
        ...kriya,
        exerciseRelations: kriya.exerciseRelations.filter(
          (rel) => rel.Exercise?.internalNotes
        ),
      };
      return newKriya;
    })
    .filter((kriya) => kriya.exerciseRelations.length);

  return (
    <div className={classes.container}>
      <Typography variant="h3">Exercises With Notes</Typography>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {withNotes.map((kriya) => (
                <>
                  {kriya.exerciseRelations.map((rel) => (
                    <ListItem
                      button
                      alignItems="flex-start"
                      key={rel.Exercise!.id}
                      component={Link}
                      to={`/kriyas/${kriya.id}/${rel.number}`}
                      divider
                    >
                      <ListItemIcon>
                        <img
                          src={rel.Exercise!.image?.url}
                          className={classes.kriyaImage}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={rel.Exercise!.name}
                        secondary={kriya.name}
                      />
                      <ListItemText secondary={rel.Exercise!.internalNotes} />
                    </ListItem>
                  ))}
                </>
              ))}
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default Notes;
