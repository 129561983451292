import React from 'react';
import Add from '@material-ui/icons/Add';
import { useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import startCase from 'lodash/startCase';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { KriyaQuery_Kriya } from './__generated__/KriyaQuery';
import {
  MergeKriyaTopic,
  MergeKriyaTopicVariables,
} from './__generated__/MergeKriyaTopic';
import { KRIYA_QUERY } from './kriyaDetails';

interface Props {
  kriya: KriyaQuery_Kriya;
  topicText?: string;
  topicSalience?: number;
}

const MERGE_KRIYA_TOPIC = gql`
  mutation MergeKriyaTopic($kriyaId: ID!, $topic: String!, $salience: Float!) {
    MergeKriyaTopic(kriyaId: $kriyaId, topic: $topic, salience: $salience) {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    minWidth: '8rem',
  },
}));

const MergeTopic: React.FC<Props> = ({ kriya, topicText, topicSalience }) => {
  const classes = useStyles();

  const [text, settext] = React.useState<string | undefined>(topicText);
  const [salience, setsalience] = React.useState<number | undefined>(
    topicSalience
  );

  const { enqueueSnackbar } = useSnackbar();

  const [mergeTopic, { loading }] = useMutation<
    MergeKriyaTopic,
    MergeKriyaTopicVariables
  >(MERGE_KRIYA_TOPIC);

  async function onSubmit() {
    if (!text || !salience) return alert('fill out all fields');
    const response = await mergeTopic({
      variables: {
        kriyaId: kriya.id,
        topic: startCase(text),
        salience: salience,
      },
      refetchQueries: [
        { query: KRIYA_QUERY, variables: { kriyaId: kriya.id } },
      ],
    });

    enqueueSnackbar(
      `${response.data?.MergeKriyaTopic?.name} added successfully`,
      {
        variant: 'success',
      }
    );
  }

  return (
    <div className={classes.row}>
      <TextField
        label="Topic Text"
        variant="outlined"
        placeholder="enter a topic"
        onChange={(e) => settext(e.target.value)}
        value={text}
      />

      <TextField
        className={classes.input}
        label="Salience"
        variant="outlined"
        type="number"
        onChange={(e) => setsalience(parseFloat(e.target.value))}
        value={salience}
        placeholder="0.1"
        inputProps={{
          step: 0.1,
          min: 0,
          max: 1,
        }}
      />
      <IconButton onClick={onSubmit} disabled={loading}>
        <Add />
      </IconButton>
    </div>
  );
};

export default MergeTopic;
