import React, { useMemo } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Compare from '@material-ui/icons/Compare';
import ListAlt from '@material-ui/icons/ListAlt';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import { useDialog } from '../navigation/dialogProvider';
import {
  ExerciseQuery,
  ExerciseQueryVariables,
  ExerciseQuery_Exercise,
} from './__generated__/ExerciseQuery';
import {
  UpdateExerciseInternalNotes,
  UpdateExerciseInternalNotesVariables,
} from './__generated__/UpdateExerciseInternalNotes';
import {
  UpdateExerciseNotes,
  UpdateExerciseNotesVariables,
} from './__generated__/UpdateExerciseNotes';
import {
  UpdateExerciseName,
  UpdateExerciseNameVariables,
} from './__generated__/UpdateExerciseName';

import ExercisePart, { PART_FRAGMENT } from './exercisePart';
import Loading from '../shared/loading';
import ExerciseMetadata from './exerciseMetadata';

export const EXERCISE_QUERY = gql`
  query ExerciseQuery($exerciseId: ID!) {
    Exercise(id: $exerciseId) {
      id
      name
      notes
      internalNotes
      asanas {
        id
        name
      }
      mantras {
        id
        name
      }
      eyeFocuses {
        id
        name
      }
      breaths {
        id
        name
      }
      mudras {
        id
        name
      }
      parts {
        ...PartFragment
      }
    }
  }
  ${PART_FRAGMENT}
`;

const UPDATE_EXERCISE_INTERNAL_NOTES = gql`
  mutation UpdateExerciseInternalNotes($id: ID!, $internalNotes: String) {
    UpdateExercise(id: $id, internalNotes: $internalNotes) {
      id
      internalNotes
    }
  }
`;

const UPDATE_EXERCISE_NOTES = gql`
  mutation UpdateExerciseNotes($id: ID!, $notes: String) {
    UpdateExercise(id: $id, notes: $notes) {
      id
      notes
    }
  }
`;

const UPDATE_EXERCISE_NAME = gql`
  mutation UpdateExerciseName($id: ID!, $name: String) {
    UpdateExercise(id: $id, name: $name) {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.spacing() * 2,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  exerciseToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  disabled: {
    color: '#95a5a6',
  },
  notesHeader: {
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
  },
}));

interface Props {
  exerciseId: string;
  LOTExerciseData: any;
}

const KriyaDetails: React.FC<Props> = ({ exerciseId, LOTExerciseData }) => {
  const classes = useStyles();
  const [dialog, close, ref] = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useQuery<
    ExerciseQuery,
    ExerciseQueryVariables
  >(EXERCISE_QUERY, { variables: { exerciseId } });

  const [tab, setTab] = React.useState<string | null>('instructions');

  const exercise: ExerciseQuery_Exercise | null =
    data && data.Exercise && data.Exercise.length ? data.Exercise[0] : null;

  const LOTText = useMemo(() => {
    return LOTExerciseData?.parts?.reduce(
      (combined: string, part: any, partIndex: number) => {
        return (
          combined +
          `\nPart ${partIndex + 1}${part.name ? ` - ${part.name}` : ''}\n` +
          part.instructions?.reduce((combined: string, instruction: string) => {
            return combined + instruction + '\n';
          }, '') +
          (part.toEnd.length
            ? `\nTo End\n` +
              part.toEnd?.reduce((combined: string, instruction: string) => {
                return combined + instruction + '\n';
              }, '')
            : '')
        );
      },
      ''
    );
  }, [LOTExerciseData]);

  const KMText = useMemo(() => {
    return exercise?.parts.reduce((combined: string, part, partIndex) => {
      return (
        combined +
        `\nPart ${partIndex + 1}${part.name ? ` - ${part.name}` : ''}\n` +
        part.instructions?.reduce((combined: string, instruction) => {
          return combined + instruction.text + '\n';
        }, '') +
        (part.endingInstructions.length
          ? `\nTo End\n` +
            part.endingInstructions.reduce((combined: string, instruction) => {
              return combined + instruction.text + '\n';
            }, '')
          : '')
      );
    }, '');
  }, [exercise]);

  const [updateExerciseInternalNotes] = useMutation<
    UpdateExerciseInternalNotes,
    UpdateExerciseInternalNotesVariables
  >(UPDATE_EXERCISE_INTERNAL_NOTES);

  const [updateExerciseNotes] = useMutation<
    UpdateExerciseNotes,
    UpdateExerciseNotesVariables
  >(UPDATE_EXERCISE_NOTES);

  const [updateExerciseName] =
    useMutation<UpdateExerciseName, UpdateExerciseNameVariables>(
      UPDATE_EXERCISE_NAME
    );

  async function internalNoteDoubleClicked() {
    dialog({
      title: 'Enter the note text',
      inputDefaultText: exercise?.internalNotes ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const internalNotes = ref?.current?.value?.trim() || null;
            close();
            try {
              const response = await updateExerciseInternalNotes({
                variables: { id: exercise!.id, internalNotes },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateExercise?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function noteDoubleClicked() {
    dialog({
      title: 'Enter the note text',
      inputDefaultText: exercise?.notes ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const notes = ref?.current?.value?.trim() || null;
            close();
            try {
              const response = await updateExerciseNotes({
                variables: { id: exercise!.id, notes },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateExercise?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function nameDoubleClicked() {
    dialog({
      title: 'Enter the name',
      inputDefaultText: exercise?.name ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value?.trim() || null;
            close();
            try {
              if (!name) throw Error('No name provided');
              const response = await updateExerciseName({
                variables: { id: exercise!.id, name },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateExercise?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3" onDoubleClick={nameDoubleClicked}>
        {exercise?.name}
      </Typography>
      <div className={classes.exerciseToolbar}>
        <div className={classes.row}>
          {exercise && <ExerciseMetadata exercise={exercise} />}
        </div>
        <ToggleButtonGroup
          value={tab}
          exclusive
          onChange={(e, tabId) => setTab(tabId)}
          aria-label="text alignment"
        >
          <ToggleButton value="instructions" aria-label="left aligned">
            <ListAlt />
          </ToggleButton>
          <ToggleButton value="diff" aria-label="centered">
            <Compare />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {tab === 'instructions' && (
        <>
          <div>
            <Typography variant="h5" className={classes.notesHeader}>
              Internal Notes
            </Typography>
            <div onDoubleClick={internalNoteDoubleClicked}>
              {exercise?.internalNotes ? (
                <Typography paragraph>{exercise?.internalNotes}</Typography>
              ) : (
                <Typography paragraph className={classes.disabled}>
                  Enter text...
                </Typography>
              )}
            </div>
          </div>

          <List>
            {exercise &&
              exercise.parts.map((part, index) => (
                <ExercisePart
                  key={part.id}
                  part={part}
                  index={index}
                  numParts={exercise.parts.length}
                  exerciseId={exercise.id}
                />
              ))}
          </List>

          <Typography variant="h5" className={classes.notesHeader}>
            Notes
          </Typography>
          <div onDoubleClick={noteDoubleClicked}>
            {exercise?.notes ? (
              <ReactMarkdown remarkPlugins={[gfm]} children={exercise?.notes} />
            ) : (
              <Typography paragraph className={classes.disabled}>
                Enter text...
              </Typography>
            )}
          </div>
        </>
      )}
      {loading ? (
        <Loading />
      ) : (
        tab === 'diff' && (
          <ReactDiffViewer
            leftTitle="Library of Teachings"
            oldValue={LOTText}
            rightTitle="Kundalini Mobile"
            newValue={KMText}
            splitView={true}
            compareMethod={DiffMethod.WORDS}
          />
        )
      )}
    </div>
  );
};

export default KriyaDetails;
