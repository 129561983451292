import React, { createContext, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import firebase from 'firebase/app';
import 'firebase/auth';

import DialogProvider from './components/navigation/dialogProvider';
import './components/shared/loading';
import client from './config/apolloClient';
import Router from './components/navigation/router';
import { makeStyles } from '@material-ui/core/styles';
import Loading from './components/shared/loading';

const firebaseConfig = {
  apiKey: 'AIzaSyCP1S-bg0VKnerbW6-BI_fvaB6v9HxZaZI',
  authDomain: 'kundalini-yoga.firebaseapp.com',
  databaseURL: 'https://kundalini-yoga.firebaseio.com',
  projectId: 'kundalini-yoga',
  storageBucket: 'kundalini-yoga.appspot.com',
  messagingSenderId: '494407824717',
  appId: '1:494407824717:web:f267beebe0fd2c7fa7b304',
  measurementId: 'G-J8JKV10134',
};

firebase.initializeApp(firebaseConfig);

export const User = createContext<firebase.User | null>(null);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

// Hook to subscribe to the firebase user
function useUser() {
  const [user, setUser] = useState<firebase.User | null | undefined>();

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged((user) => setUser(user));
    return () => unsubscribe();
  });

  return user;
}

const App: React.FC = () => {
  const classes = useStyles();
  const user = useUser();

  return user === undefined ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <User.Provider value={user}>
          <ApolloProvider client={client}>
            <DialogProvider>
              <Router user={user} />
            </DialogProvider>
          </ApolloProvider>
        </User.Provider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
