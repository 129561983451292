import React, { useState, useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Slider from '@material-ui/core/Slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { startCase, sum, values } from 'lodash';
import copy from 'clipboard-copy';
import Fuse from 'fuse.js';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useDialog } from '../navigation/dialogProvider';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import {
  AllKriyaImages,
  AllKriyaImages_KriyaImage,
} from './__generated__/AllKriyaImages';

import { AddImage, AddImageVariables } from './__generated__/AddImage';

const KRIYA_IMAGES = gql`
  query AllKriyaImages {
    KriyaImage {
      id
      url
      model
      distance
      orientation # where the model is facing (e.g. portrait)
      position
      torso # e.g. straight, stretched back, stretched forward
      # Limbs
      arms
      elbows
      wrists
      hands
      mudra
      legs
      knees
      ankles
      feet
      # Face
      mouth
      eyes
    }
  }
`;

export const ADD_IMAGE = gql`
  mutation AddImage($id: ID!) {
    CreateKriyaImage(id: $id) {
      id
      url
    }
  }
`;

type FacetMap = {
  [facetName: string]: { [facetValue: string]: boolean };
};

type FacetCount = {
  [facetName: string]: { [facetValue: string]: number };
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  sideBar: {
    padding: '1em',
    backgroundColor: grey[100],
    flex: 1,
    height: '100%',
    overflowY: 'scroll',
  },
  imageGrid: {
    flex: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  gridInfoRow: {
    backgroundColor: grey[100],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
  },
  slider: {
    zIndex: theme.zIndex.drawer + 2,
    maxWidth: '30%',
  },
  appBarSpacer: theme.mixins.toolbar,
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, close, ref] = useDialog();

  const { loading, data, error } = useQuery<AllKriyaImages>(KRIYA_IMAGES);
  const kriyaImages: AllKriyaImages_KriyaImage[] =
    data && data.KriyaImage
      ? (data.KriyaImage as AllKriyaImages_KriyaImage[])
      : [];

  const [facetMap, setFacetMap] = useState<FacetMap>({});

  const [addImage] = useMutation<AddImage, AddImageVariables>(ADD_IMAGE);

  const keysToExclude: string[] = ['id', '__typename', 'url'];

  useEffect(() => {
    const facets: FacetMap = kriyaImages.reduce<FacetMap>((map, image) => {
      Object.keys(image!)
        .filter((key) => !keysToExclude.includes(key))
        .forEach((facetKey) => {
          if (!map[facetKey]) {
            map[facetKey] = {};
          }
          const value: string = String((image! as any)[facetKey]);
          map[facetKey][value] = true;
        });
      return map;
    }, {});
    setFacetMap(facets);
  }, [data]);

  const [searchString, setSearchString] = useState<string>('');

  const searchedImages = useMemo(() => {
    if (searchString.length === 0) return kriyaImages;
    const options = {
      keys: ['id', ...Object.keys(facetMap)],
      shouldSort: true,
      threshold: 0.6,
    };
    const fuse = new Fuse<AllKriyaImages_KriyaImage>(kriyaImages, options);
    return fuse.search(searchString);
  }, [searchString, facetMap]);

  const facetCount: FacetCount = useMemo(
    () =>
      Object.keys(facetMap).reduce<FacetCount>((map, facetKey) => {
        const imagesForFacetKey = searchedImages.filter((image) =>
          Object.keys(image!)
            .filter((key) => !keysToExclude.includes(key))
            .filter((key) => key !== facetKey)
            .every((facetKey) => {
              const value: string = String((image! as any)[facetKey]);
              return facetMap[facetKey][value];
            })
        );
        map[facetKey] = Object.keys(facetMap[facetKey]).reduce<{
          [facetValue: string]: number;
        }>((facetValueMap, facetValue) => {
          facetValueMap[facetValue] = imagesForFacetKey.filter((image) => {
            const value: string = String((image! as any)[facetKey]);
            return value === facetValue;
          }).length;
          return facetValueMap;
        }, {});
        return map;
      }, {}),
    [facetMap, searchedImages]
  );

  const filteredImages = useMemo(() => {
    if (Object.keys(facetMap).length === 0) return [];
    return searchedImages.filter((image) =>
      Object.keys(image!)
        .filter((key) => !keysToExclude.includes(key))
        .every((facetKey) => {
          const value: string = String((image! as any)[facetKey]);
          return facetMap[facetKey][value];
        })
    );
  }, [facetMap, searchedImages]);

  const [numColumns, setNumColumns] = useState<number>(3);

  if (!data || !data.KriyaImage) return null;

  const toggleValue = (facetKey: string, facetValue: string) => {
    const updated = Object.assign({}, facetMap);
    updated[facetKey][facetValue] = !updated[facetKey][facetValue];
    setFacetMap(updated);
  };

  const toggleSelectAll = (facetKey: string) => {
    const updated = Object.assign({}, facetMap);
    if (values(updated[facetKey]).every((value) => value))
      Object.keys(updated[facetKey]).forEach(
        (value) => (updated[facetKey][value] = false)
      );
    else
      Object.keys(updated[facetKey]).forEach(
        (value) => (updated[facetKey][value] = true)
      );
    setFacetMap(updated);
  };

  const onAddImagePressed = () => {
    dialog({
      title: 'Enter ID',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const id = ref?.current?.value;
            if (!id) throw Error('No ID');
            close();
            try {
              const response = await addImage({
                variables: { id },
                refetchQueries: [{ query: KRIYA_IMAGES }],
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Image added sucessfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Add
        </Button>,
      ],
    });
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.sideBar}>
        <div className={classes.row}>
          <TextField
            id="search-input"
            label="Search"
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            margin="normal"
            variant="outlined"
          />
          <IconButton onClick={onAddImagePressed}>
            <AddIcon />
          </IconButton>
        </div>

        {Object.keys(facetMap).map((facetKey) => {
          return (
            <React.Fragment key={facetKey}>
              <h2>{startCase(facetKey)}</h2>
              <List>
                <ListItem
                  key={`${facetKey}-select-all`}
                  role={undefined}
                  dense
                  button
                  onClick={() => toggleSelectAll(facetKey)}
                >
                  <ListItemText
                    primary={`Select All (${sum(
                      values(facetCount[facetKey])
                    )})`}
                  />
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={values(facetMap[facetKey]).every(
                        (value) => value
                      )}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                </ListItem>
                {Object.keys(facetMap[facetKey]).map((facetValue) => {
                  return (
                    <ListItem
                      key={`${facetKey}-${facetValue}`}
                      role={undefined}
                      dense
                      button
                      onClick={() => toggleValue(facetKey, facetValue)}
                    >
                      <ListItemText
                        primary={`${facetValue} (${facetCount[facetKey][facetValue]})`}
                      />
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={facetMap[facetKey][facetValue]}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                    </ListItem>
                  );
                })}
              </List>
            </React.Fragment>
          );
        })}
      </Paper>
      <Paper className={classes.imageGrid}>
        <div className={classes.gridInfoRow}>
          <div>{filteredImages.length} results</div>
          <Slider
            className={classes.slider}
            value={numColumns}
            onChange={(_, value) => setNumColumns(value as number)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={5}
          />
        </div>
        <AutoSizer defaultWidth={1920} defaultHeight={1080}>
          {({ height, width }) => {
            const imageWidth = Math.floor(width / numColumns);
            const imageHeight = Math.floor((imageWidth * 3) / 4);
            const rowCount = Math.ceil(filteredImages.length / numColumns);
            return (
              <FixedSizeGrid
                width={width}
                height={height}
                columnCount={numColumns}
                columnWidth={imageWidth}
                rowHeight={imageHeight}
                rowCount={rowCount}
              >
                {({ columnIndex, rowIndex, style }) => {
                  const singleColumnIndex = columnIndex + rowIndex * numColumns;
                  const image = filteredImages[singleColumnIndex];
                  if (!image) return <div style={style} />;
                  return (
                    <div
                      style={style}
                      onDoubleClick={async () => {
                        await copy(image.id);
                        enqueueSnackbar(`${image.id} copied to clipboard`, {
                          variant: 'success',
                        });
                      }}
                    >
                      <img
                        src={image.url}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  );
                }}
              </FixedSizeGrid>
            );
          }}
        </AutoSizer>
      </Paper>
    </div>
  );
};

export default Home;
