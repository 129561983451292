import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';

import Loading from '../shared/loading';
import {
  PublicationDetailsQuery,
  PublicationDetailsQueryVariables,
  PublicationDetailsQuery_Publication,
} from './__generated__/PublicationDetailsQuery';

const PUBLICATION_DETAILS_QUERY = gql`
  query PublicationDetailsQuery($publicationId: ID!) {
    Publication(id: $publicationId) {
      id
      name
      imageUrl
      kriyaRelations(orderBy: pageNumber_asc) {
        Kriya {
          id
          name
          image {
            id
            url
          }
        }
        pageNumber
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
  },
  contentColumn: {
    flex: 1,
    marginTop: theme.spacing() * 4,
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  columnHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing() * 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
  disabled: {
    color: '#95a5a6',
  },
  headerImage: {
    objectFit: 'scale-down',
    width: 300,
    height: 300,
  },
  header: {
    display: 'flex',
  },
}));

const PublicationDetails: React.FC = () => {
  const { publicationId } = useParams();
  const classes = useStyles();

  const { data, loading, error } = useQuery<
    PublicationDetailsQuery,
    PublicationDetailsQueryVariables
  >(PUBLICATION_DETAILS_QUERY, { variables: { publicationId } });

  const publication: PublicationDetailsQuery_Publication | null =
    data && data.Publication && data.Publication.length
      ? data.Publication[0]
      : null;

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          src={publication?.imageUrl ?? ''}
          className={classes.headerImage}
          alt=""
        />
        <Typography variant="h3">{publication?.name}</Typography>
      </div>

      <div className={classes.contentColumn}>
        <div className={classes.columnHeading}>
          <Typography variant="h4">
            Kriyas ({publication?.kriyaRelations?.length ?? 0})
          </Typography>
        </div>
        <List>
          {publication?.kriyaRelations.map((relation, index) => (
            <ListItem
              button
              alignItems="flex-start"
              key={relation.Kriya!.id}
              component={Link}
              to={`/kriyas/${relation.Kriya!.id}`}
              divider
            >
              <ListItemIcon>
                <img
                  src={relation.Kriya!.image?.url}
                  className={classes.kriyaImage}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText
                primary={relation.Kriya!.name}
                secondary={
                  relation.pageNumber ? `Page ${relation.pageNumber}` : ''
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default PublicationDetails;
