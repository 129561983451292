import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link, useLocation } from 'react-router-dom';

import {
  KriyasQuery,
  KriyasQuery_PopularKriyas,
} from './__generated__/KriyasQuery';

import Loading from '../shared/loading';
import KriyaDetails from '../kriyaDetails/kriyaDetails';

const KRIYAS_QUERY = gql`
  query KriyasQuery($first: Int!, $offset: Int!) {
    AllKriyasCount
    PopularKriyas(first: $first, offset: $offset) {
      id
      __typename
      name
      image {
        id
        url
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  pagination: {
    alignSelf: 'center',
    margin: theme.spacing() * 2,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
}));

// A custom hook that parses the pagination query
function usePagination(): number {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  return page;
}

const Kriyas: React.FC = () => {
  const classes = useStyles();
  const page = usePagination();

  const [resultsPerPage, setResultsPerPage] = useState(20);

  const { loading, data, error } = useQuery<KriyasQuery>(KRIYAS_QUERY, {
    variables: { offset: (page - 1) * resultsPerPage, first: resultsPerPage },
  });

  const pageCount = Math.ceil((data?.AllKriyasCount ?? 0) / resultsPerPage);

  const kriyas: KriyasQuery_PopularKriyas[] =
    data && data.PopularKriyas
      ? (data.PopularKriyas as KriyasQuery_PopularKriyas[])
      : [];
  return (
    <div className={classes.container}>
      <Typography variant="h3">Kriyas</Typography>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {kriyas.map((kriya, index) => (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={kriya.id}
                  component={Link}
                  to={`/kriyas/${kriya.id}`}
                  divider
                >
                  <ListItemIcon>
                    <img
                      src={kriya.image?.url}
                      className={classes.kriyaImage}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText primary={kriya.name} />
                </ListItem>
              ))}
            </List>
          </div>
          <Pagination
            className={classes.pagination}
            page={page}
            count={pageCount}
            renderItem={(item) => (
              <PaginationItem
                component={Link}
                to={`/kriyas${item.page === 1 ? '' : `?page=${item.page}`}`}
                {...item}
              />
            )}
          />
        </>
      )}
    </div>
  );
};

export default Kriyas;
