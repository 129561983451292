import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import { useDialog } from '../navigation/dialogProvider';
import {
  AsanaQuery,
  AsanaQueryVariables,
  AsanaQuery_Asana,
  AsanaQuery_Asana_exercises,
} from './__generated__/AsanaQuery';
import Loading from '../shared/loading';

import {
  UpdateAsanaName,
  UpdateAsanaNameVariables,
} from './__generated__/UpdateAsanaName';

const ASANA_QUERY = gql`
  query AsanaQuery($asanaId: ID!) {
    Asana(id: $asanaId) {
      id
      name
      image {
        id
        url
      }
      exercises(orderBy: id_asc) {
        id
        name
        image {
          id
          url
        }
        kriyaRelation {
          number
          Kriya {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_ASANA_NAME = gql`
  mutation UpdateAsanaName($id: ID!, $name: String!) {
    UpdateAsana(id: $id, name: $name) {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
  },
  title: {
    marginBottom: theme.spacing() * 2,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
}));

const AsanaDetails: React.FC = () => {
  const classes = useStyles();
  const { asanaId } = useParams();
  const [dialog, close, ref] = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useQuery<AsanaQuery, AsanaQueryVariables>(
    ASANA_QUERY,
    {
      variables: { asanaId },
    }
  );

  const [updateAsanaName] = useMutation<
    UpdateAsanaName,
    UpdateAsanaNameVariables
  >(UPDATE_ASANA_NAME);

  if (loading) return <Loading />;

  const asana: AsanaQuery_Asana | null =
    data && data.Asana && data.Asana.length ? data.Asana[0] : null;

  const exercises: AsanaQuery_Asana_exercises[] = asana?.exercises ?? [];

  async function asanaNameDoubleClicked() {
    dialog({
      title: 'Enter the asana name',
      inputDefaultText: asana?.name ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value;
            if (!name) throw Error('No name');
            close();
            try {
              const response = await updateAsanaName({
                variables: { id: asana!.id, name },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateAsana?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  return (
    <div className={classes.container}>
      <Typography
        variant="h3"
        className={classes.title}
        onDoubleClick={asanaNameDoubleClicked}
      >
        {asana?.name}
      </Typography>
      <Typography variant="h5">Exercises</Typography>
      <List>
        {exercises.map((exercise, i) => (
          <ListItem
            key={i}
            component={Link}
            to={`/kriyas/${exercise.kriyaRelation?.Kriya?.id}/${exercise.kriyaRelation?.number}`}
            button
          >
            <ListItemIcon>
              <img
                src={exercise.image?.url}
                className={classes.kriyaImage}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              primary={exercise.name}
              secondary={exercise.kriyaRelation?.Kriya?.name}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default AsanaDetails;
