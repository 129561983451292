import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import { useDialog } from '../navigation/dialogProvider';
import {
  MudraQuery,
  MudraQueryVariables,
  MudraQuery_Mudra,
  MudraQuery_Mudra_exercises,
} from './__generated__/MudraQuery';
import Loading from '../shared/loading';

import {
  UpdateMudraName,
  UpdateMudraNameVariables,
} from './__generated__/UpdateMudraName';

const MUDRA_QUERY = gql`
  query MudraQuery($mudraId: ID!) {
    Mudra(id: $mudraId) {
      id
      name
      exercises(orderBy: id_asc) {
        id
        name
        image {
          id
          url
        }
        kriyaRelation {
          number
          Kriya {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_MUDRA_NAME = gql`
  mutation UpdateMudraName($id: ID!, $name: String!) {
    UpdateMudra(id: $id, name: $name) {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
  },
  title: {
    marginBottom: theme.spacing() * 2,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
}));

const MudraDetails: React.FC = () => {
  const classes = useStyles();
  const { mudraId } = useParams();
  const [dialog, close, ref] = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useQuery<MudraQuery, MudraQueryVariables>(
    MUDRA_QUERY,
    {
      variables: { mudraId },
    }
  );

  const [updateMudraName] = useMutation<
    UpdateMudraName,
    UpdateMudraNameVariables
  >(UPDATE_MUDRA_NAME);

  if (loading) return <Loading />;

  const mudra: MudraQuery_Mudra | null =
    data && data.Mudra && data.Mudra.length ? data.Mudra[0] : null;

  const exercises: MudraQuery_Mudra_exercises[] = mudra?.exercises ?? [];

  async function mudraNameDoubleClicked() {
    dialog({
      title: 'Enter the mudra name',
      inputDefaultText: mudra?.name ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value;
            if (!name) throw Error('No name');
            close();
            try {
              const response = await updateMudraName({
                variables: { id: mudra!.id, name },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMudra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  return (
    <div className={classes.container}>
      <Typography
        variant="h3"
        className={classes.title}
        onDoubleClick={mudraNameDoubleClicked}
      >
        {mudra?.name}
      </Typography>
      <Typography variant="h5">Exercises</Typography>
      <List>
        {exercises.map((exercise, i) => (
          <ListItem
            key={i}
            component={Link}
            to={`/kriyas/${exercise.kriyaRelation?.Kriya?.id}/${exercise.kriyaRelation?.number}`}
            button
          >
            <ListItemIcon>
              <img
                src={exercise.image?.url}
                className={classes.kriyaImage}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              primary={exercise.name}
              secondary={exercise.kriyaRelation?.Kriya?.name}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MudraDetails;
