import React, { useState, useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useDialog } from '../navigation/dialogProvider';
import KriyaTopics, { KriyaTopicFragment } from './kriyaTopics';
import {
  UpdateKriyaNotes,
  UpdateKriyaNotesVariables,
} from './__generated__/UpdateKriyaNotes';

import {
  KriyaQuery,
  KriyaQueryVariables,
  KriyaQuery_Kriya,
} from './__generated__/KriyaQuery';

import ExerciseDetails from './exerciseDetails';

export const KRIYA_QUERY = gql`
  query KriyaQuery($kriyaId: ID!) {
    Kriya(id: $kriyaId) {
      id
      LOTData
      name
      notes
      image {
        id
        url
      }
      publicationRelations {
        Publication {
          id
          name
        }
        pageNumber
      }
      __typename
      exercises {
        id
        LOTId
        image {
          id
          url
        }
        __typename
        name
      }
      ...KriyaTopicFragment
    }
  }
  ${KriyaTopicFragment}
`;

const UPDATE_KRIYA_NOTES = gql`
  mutation UpdateKriyaNotes($id: ID!, $notes: String) {
    UpdateKriya(id: $id, notes: $notes) {
      id
      notes
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
  },
  header: {
    display: 'flex',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing() * 2,
  },
  description: {
    marginTop: theme.spacing() * 2,
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
  },
  sideBar: {
    flexDirection: 'column',
    width: 400,
  },
  details: {
    flex: 1,
  },
  headerImage: {
    objectFit: 'scale-down',
    width: 300,
    height: 300,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
  exercisesHeder: {
    marginTop: theme.spacing() * 4,
  },
  publications: {
    display: 'flex',
    flexDirection: 'row',
  },
  publication: {
    marginRight: theme.spacing() * 2,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const KriyaDetails: React.FC = () => {
  const classes = useStyles();
  const [dialog, close, ref] = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { kriyaId, exerciseIndex } = useParams();

  const { loading, data, error } = useQuery<KriyaQuery, KriyaQueryVariables>(
    KRIYA_QUERY,
    {
      variables: { kriyaId },
    }
  );

  const [updateKriyaNotes] =
    useMutation<UpdateKriyaNotes, UpdateKriyaNotesVariables>(
      UPDATE_KRIYA_NOTES
    );

  const kriya: KriyaQuery_Kriya | null =
    data && data.Kriya && data.Kriya.length ? data.Kriya[0] : null;

  const selectedExercise = useMemo(
    () => kriya?.exercises[exerciseIndex],
    [kriya, exerciseIndex]
  );

  const LOTExerciseData = useMemo(() => {
    return kriya?.LOTData?.activities?.find(
      (exercise: any) => exercise.id === selectedExercise?.LOTId
    );
  }, [kriya, selectedExercise]);

  async function noteDoubleClicked() {
    dialog({
      title: 'Enter the note text',
      inputDefaultText: kriya?.notes ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const notes = ref?.current?.value?.trim() || null;
            close();
            try {
              const response = await updateKriyaNotes({
                variables: { id: kriya!.id, notes },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateKriya?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src={kriya?.image?.url} className={classes.headerImage} alt="" />
        <div className={classes.headerText}>
          <Typography variant="h3">{kriya?.name}</Typography>
          <div className={classes.publications}>
            {kriya?.publicationRelations?.map((relation) => (
              <React.Fragment key={relation.Publication!.id}>
                <Chip
                  key={relation.Publication!.id}
                  className={classes.publication}
                  label={
                    relation.Publication!.name +
                    (relation.pageNumber ? ` (p.${relation.pageNumber})` : '')
                  }
                  component={Link}
                  to={`/publications/${relation.Publication!.id}`}
                />
              </React.Fragment>
            ))}
          </div>

          <Typography
            className={classes.description}
            paragraph
            variant="subtitle1"
            onDoubleClick={noteDoubleClicked}
          >
            {kriya?.notes}
          </Typography>
          {kriya && <KriyaTopics kriya={kriya} />}
        </div>
      </div>

      <Typography className={classes.exercisesHeder} variant="h4">
        Exercises
      </Typography>

      <div className={classes.columns}>
        <div className={classes.sideBar}>
          <List>
            {kriya &&
              kriya.exercises.map((exercise, index) => (
                <ListItem
                  component={Link}
                  to={`/kriyas/${kriyaId}/${index}`}
                  key={exercise.id}
                  selected={exercise === selectedExercise}
                  divider
                >
                  <img
                    src={exercise.image?.url}
                    className={classes.kriyaImage}
                    alt=""
                  />
                  <ListItemText primary={exercise.name} />
                </ListItem>
              ))}
          </List>
        </div>
        <div className={classes.details}>
          {selectedExercise && (
            <ExerciseDetails
              exerciseId={selectedExercise.id}
              LOTExerciseData={LOTExerciseData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KriyaDetails;
