import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hearing from '@material-ui/icons/Hearing';
import Waves from '@material-ui/icons/Waves';
import PanTool from '@material-ui/icons/PanTool';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import { useSnackbar } from 'notistack';

import {
  MetadataSearchQuery,
  MetadataSearchQueryVariables,
  MetadataSearchQuery_Search,
  MetadataSearchQuery_Search_Asana,
  MetadataSearchQuery_Search_Breath,
  MetadataSearchQuery_Search_EyeFocus,
  MetadataSearchQuery_Search_Mantra,
  MetadataSearchQuery_Search_Mudra,
} from './__generated__/MetadataSearchQuery';

import { AssignAsana, AssignAsanaVariables } from './__generated__/AssignAsana';
import {
  AssignBreath,
  AssignBreathVariables,
} from './__generated__/AssignBreath';
import {
  AssignEyeFocus,
  AssignEyeFocusVariables,
} from './__generated__/AssignEyeFocus';
import {
  AssignMantra,
  AssignMantraVariables,
} from './__generated__/AssignMantra';
import { AssignMudra, AssignMudraVariables } from './__generated__/AssignMudra';

type Props = {
  exerciseId: string;
};

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 300,
    margin: theme.spacing(),
    padding: 0,
  },

  input: {
    color: 'inherit',
    padding: 0,
  },
  inputLabel: {
    color: 'inherit',
  },
}));

const SEARCH_QUERY = gql`
  query MetadataSearchQuery($string: String!) {
    Search(string: $string) {
      ... on Asana {
        id
        name
      }
      ... on Breath {
        id
        name
        id
        name
      }
      ... on EyeFocus {
        id
        name
      }
      ... on Mantra {
        id
        name
      }
      ... on Mudra {
        id
        name
      }
    }
  }
`;

const ASSIGN_ASANA = gql`
  mutation AssignAsana($from: _ExerciseInput!, $to: _AsanaInput!) {
    MergeExerciseAsanas(from: $from, to: $to) {
      from {
        id
        asanas {
          id
        }
      }
    }
  }
`;

const ASSIGN_BREATH = gql`
  mutation AssignBreath($from: _ExerciseInput!, $to: _BreathInput!) {
    MergeExerciseBreaths(from: $from, to: $to) {
      from {
        id
        breaths {
          id
        }
      }
    }
  }
`;

const ASSIGN_EYE_FOCUS = gql`
  mutation AssignEyeFocus($from: _ExerciseInput!, $to: _EyeFocusInput!) {
    MergeExerciseEyeFocuses(from: $from, to: $to) {
      from {
        id
        eyeFocuses {
          id
        }
      }
    }
  }
`;

const ASSIGN_MANTRA = gql`
  mutation AssignMantra($from: _ExerciseInput!, $to: _MantraInput!) {
    MergeExerciseMantras(from: $from, to: $to) {
      from {
        id
        mantras {
          id
        }
      }
    }
  }
`;

const ASSIGN_MUDRA = gql`
  mutation AssignMudra($from: _ExerciseInput!, $to: _MudraInput!) {
    MergeExerciseMudras(from: $from, to: $to) {
      from {
        id
        mudras {
          id
        }
      }
    }
  }
`;

const Option: React.FC<{
  option: MetadataSearchQuery_Search;
  exerciseId: string;
}> = ({ option, exerciseId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [assignAsana] = useMutation<AssignAsana, AssignAsanaVariables>(
    ASSIGN_ASANA
  );
  const [assignBreath] = useMutation<AssignBreath, AssignBreathVariables>(
    ASSIGN_BREATH
  );
  const [assignEyeFocus] = useMutation<AssignEyeFocus, AssignEyeFocusVariables>(
    ASSIGN_EYE_FOCUS
  );
  const [assignMantra] = useMutation<AssignMantra, AssignMantraVariables>(
    ASSIGN_MANTRA
  );
  const [assignMudra] = useMutation<AssignMudra, AssignMudraVariables>(
    ASSIGN_MUDRA
  );

  let id = '';
  let icon: React.ReactElement | null = null;
  let name = '';
  let onClick: () => Promise<any>;

  switch (option.__typename) {
    case 'Asana': {
      const asana = option as MetadataSearchQuery_Search_Asana;
      id = asana.id;
      icon = <EmojiPeople />;
      name = asana.name;
      onClick = () =>
        assignAsana({
          variables: { from: { id: exerciseId }, to: { id: asana.id } },
        });
      break;
    }
    case 'Breath': {
      const breath = option as MetadataSearchQuery_Search_Breath;
      id = breath.id;
      icon = <Waves />;
      name = breath.name;
      onClick = () =>
        assignBreath({
          variables: { from: { id: exerciseId }, to: { id: breath.id } },
        });
      break;
    }
    case 'EyeFocus': {
      const eyeFocus = option as MetadataSearchQuery_Search_EyeFocus;
      id = eyeFocus.id;
      icon = <RemoveRedEye />;
      name = eyeFocus.name;
      onClick = () =>
        assignEyeFocus({
          variables: { from: { id: exerciseId }, to: { id: eyeFocus.id } },
        });
      break;
    }
    case 'Mantra': {
      const mantra = option as MetadataSearchQuery_Search_Mantra;
      id = mantra.id;
      icon = <Hearing />;
      name = mantra.name;
      onClick = () =>
        assignMantra({
          variables: { from: { id: exerciseId }, to: { id: mantra.id } },
        });
      break;
    }
    case 'Mudra': {
      const mudra = option as MetadataSearchQuery_Search_Mudra;
      id = mudra.id;
      icon = <PanTool />;
      name = mudra.name;
      onClick = () =>
        assignMudra({
          variables: { from: { id: exerciseId }, to: { id: mudra.id } },
        });
      break;
    }
  }

  return (
    <ListItem
      key={id}
      onClick={async () => {
        try {
          await onClick();
          enqueueSnackbar('assigned successfully', {
            variant: 'success',
          });
        } catch (e) {
          alert(e);
        }
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

const MetadataSearch: React.FC<Props> = ({ exerciseId }) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  const { loading, data, error } = useQuery<
    MetadataSearchQuery,
    MetadataSearchQueryVariables
  >(SEARCH_QUERY, { variables: { string: searchString } });

  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id="search"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>
        (option as any).name === (value as any).name
      }
      renderOption={(option) => (
        <Option option={option} exerciseId={exerciseId} />
      )}
      getOptionLabel={(option) => (option as any).name}
      options={data?.Search ?? []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          label="Assign"
          InputLabelProps={{
            className: classes.inputLabel,
          }}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MetadataSearch;
