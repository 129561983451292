import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useDialog } from '../navigation/dialogProvider';
import slugify from 'slugify';
import { useSnackbar } from 'notistack';

import { AsanasQuery, AsanasQuery_Asana } from './__generated__/AsanasQuery';
import { AddAsana, AddAsanaVariables } from './__generated__/AddAsana';

import Loading from '../shared/loading';

const ASANA_FRAGMENT = gql`
  fragment AsanaFragment on Asana {
    id
    __typename
    name
    image {
      id
      url
    }
  }
`;

const ASANAS_QUERY = gql`
  query AsanasQuery {
    Asana(orderBy: name_asc) {
      ...AsanaFragment
    }
  }
  ${ASANA_FRAGMENT}
`;

export const ADD_ASANA = gql`
  mutation AddAsana($id: ID!, $name: String!) {
    MergeAsana(id: $id, name: $name) {
      ...AsanaFragment
    }
  }
  ${ASANA_FRAGMENT}
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Asanas: React.FC = () => {
  const classes = useStyles();

  const { loading, data, error } = useQuery<AsanasQuery>(ASANAS_QUERY);

  const { enqueueSnackbar } = useSnackbar();
  const [dialog, close, ref] = useDialog();

  const [addAsana] = useMutation<AddAsana, AddAsanaVariables>(ADD_ASANA);

  const onAddAsanaPressed = () => {
    dialog({
      title: 'Enter an name',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value;
            if (!name) throw Error('No Text');
            const id = slugify(name, {
              lower: true,
              strict: true,
            });
            close();
            try {
              const response = await addAsana({
                variables: { id, name },
                refetchQueries: [{ query: ASANAS_QUERY }],
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Asana added sucessfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Add
        </Button>,
      ],
    });
  };

  const asanas: AsanasQuery_Asana[] =
    data && data.Asana ? (data.Asana as AsanasQuery_Asana[]) : [];
  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <Typography variant="h3">Asanas</Typography>
        <IconButton onClick={onAddAsanaPressed}>
          <Add />
        </IconButton>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {asanas.map((asana, index) => (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={asana.id}
                  component={Link}
                  to={`/asanas/${asana.id}`}
                  divider
                >
                  <ListItemIcon>
                    <img
                      src={asana.image?.url}
                      className={classes.kriyaImage}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText primary={asana.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default Asanas;
