import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useDialog } from '../navigation/dialogProvider';
import slugify from 'slugify';
import { useSnackbar } from 'notistack';

import { MudrasQuery, MudrasQuery_Mudra } from './__generated__/MudrasQuery';
import { AddMudra, AddMudraVariables } from './__generated__/AddMudra';

import Loading from '../shared/loading';

const MUDRA_FRAGMENT = gql`
  fragment MudraFragment on Mudra {
    id
    __typename
    name
  }
`;

const MUDRAS_QUERY = gql`
  query MudrasQuery {
    Mudra(orderBy: name_asc) {
      ...MudraFragment
    }
  }
  ${MUDRA_FRAGMENT}
`;

export const ADD_MUDRA = gql`
  mutation AddMudra($id: ID!, $name: String!) {
    MergeMudra(id: $id, name: $name) {
      ...MudraFragment
    }
  }
  ${MUDRA_FRAGMENT}
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Mudras: React.FC = () => {
  const classes = useStyles();

  const { loading, data, error } = useQuery<MudrasQuery>(MUDRAS_QUERY);

  const { enqueueSnackbar } = useSnackbar();
  const [dialog, close, ref] = useDialog();

  const [addMudra] = useMutation<AddMudra, AddMudraVariables>(ADD_MUDRA);

  const onAddMudraPressed = () => {
    dialog({
      title: 'Enter an name',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value;
            if (!name) throw Error('No Text');
            const id = slugify(name, {
              lower: true,
              strict: true,
            });
            close();
            try {
              const response = await addMudra({
                variables: { id, name },
                refetchQueries: [{ query: MUDRAS_QUERY }],
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Mudra added sucessfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Add
        </Button>,
      ],
    });
  };

  const mudras: MudrasQuery_Mudra[] =
    data && data.Mudra ? (data.Mudra as MudrasQuery_Mudra[]) : [];
  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <Typography variant="h3">Mudras</Typography>
        <IconButton onClick={onAddMudraPressed}>
          <Add />
        </IconButton>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {mudras.map((mudra, index) => (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={mudra.id}
                  component={Link}
                  to={`/mudras/${mudra.id}`}
                  divider
                >
                  <ListItemText primary={mudra.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default Mudras;
