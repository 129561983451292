import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import {
  SearchQuery,
  SearchQueryVariables,
  SearchQuery_Search,
  SearchQuery_Search_Kriya,
  SearchQuery_Search_Mantra,
  SearchQuery_Search_Publication,
} from './__generated__/SearchQuery';

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: 'inherit',
    color: 'white',
    borderColor: 'white',
    width: 400,
    margin: theme.spacing(),
    padding: 0,
  },

  input: {
    color: 'inherit',
    padding: 0,
  },
  inputLabel: {
    color: 'inherit',
  },
}));

const useOptionStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {},
  image: {
    width: 100,
    height: 100,
    objectFit: 'scale-down',
  },
}));

const SEARCH_QUERY = gql`
  query SearchQuery($string: String!) {
    Search(string: $string) {
      ... on Kriya {
        id
        name
        image {
          id
          url
        }
      }
      ... on Mantra {
        id
        name
        text
      }
      ... on Publication {
        id
        name
      }
      ... on Asana {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const Option: React.FC<{ option: SearchQuery_Search }> = ({ option }) => {
  const classes = useOptionStyles();

  let id = '';
  let imageUrl = '';
  let name = '';
  let link = '';

  switch (option.__typename) {
    case 'Kriya': {
      const kriya = option as SearchQuery_Search_Kriya;
      id = kriya.id;
      imageUrl = kriya.image?.url ?? '';
      name = kriya.name;
      link = `/kriyas/${id}`;
      break;
    }
    case 'Mantra': {
      const mantra = option as SearchQuery_Search_Mantra;
      id = mantra.id;
      name = mantra.name;
      link = `/mantras/${id}`;
      break;
    }
    case 'Publication': {
      const publication = option as SearchQuery_Search_Publication;
      id = publication.id;
      name = publication.name;
      link = `/publications/${id}`;
      break;
    }
  }

  return (
    <Link to={link}>
      <div className={classes.root}>
        {imageUrl && <img src={imageUrl} className={classes.image} alt="" />}
        <Typography component="h6" variant="h6">
          {name}
        </Typography>
      </div>
    </Link>
  );
};

export default function Asynchronous() {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  const { loading, data, error } = useQuery<SearchQuery, SearchQueryVariables>(
    SEARCH_QUERY,
    { variables: { string: searchString } }
  );

  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      color="inherit"
      id="search"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>
        (option as any).name === (value as any).name
      }
      renderOption={(option) => <Option option={option} />}
      getOptionLabel={(option) => (option as any).name}
      options={data?.Search ?? []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          label="Search"
          variant="filled"
          InputLabelProps={{
            className: classes.inputLabel,
          }}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
