import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Draggable } from 'react-smooth-dnd';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import arrayMove from 'array-move';
import { useSnackbar } from 'notistack';
import { useDialog } from '../navigation/dialogProvider';
import Button from '@material-ui/core/Button';

import Recorder from '../shared/recorder';
import { PartFragment } from './__generated__/PartFragment';
import {
  AddEndingInstructionToExercisePart,
  AddEndingInstructionToExercisePartVariables,
} from './__generated__/AddEndingInstructionToExercisePart';
import {
  AddInstructionToExercisePart,
  AddInstructionToExercisePartVariables,
} from './__generated__/AddInstructionToExercisePart';
import {
  UpdateEndingInstructionOrder,
  UpdateEndingInstructionOrderVariables,
} from './__generated__/UpdateEndingInstructionOrder';
import {
  UpdateInstructionOrder,
  UpdateInstructionOrderVariables,
} from './__generated__/UpdateInstructionOrder';

import InstructionListItem, {
  INSTRUCTION_FRAGMENT,
} from './instructionListItem';

type Props = {
  part: PartFragment;
  index: number;
  numParts: number;
  exerciseId: string;
};

export const PART_FRAGMENT = gql`
  fragment PartFragment on ExercisePart {
    id
    name
    instructions {
      ...InstructionFragment
    }
    endingInstructions {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

export const ADD_INSTRUCTION_MUTATION = gql`
  mutation AddInstructionToExercisePart($partId: ID!, $text: String!) {
    AddInstructionToExercisePart(partId: $partId, text: $text) {
      ...PartFragment
    }
  }
  ${PART_FRAGMENT}
`;

export const ADD_ENDING_INSTRUCTION_MUTATION = gql`
  mutation AddEndingInstructionToExercisePart($partId: ID!, $text: String!) {
    AddEndingInstructionToExercisePart(partId: $partId, text: $text) {
      ...PartFragment
    }
  }
  ${PART_FRAGMENT}
`;

export const UPDATE_INSTRUCTION_ORDER_MUTATIOIN = gql`
  mutation UpdateInstructionOrder($partId: ID!, $instructionIds: [ID!]!) {
    UpdateInstructionOrder(partId: $partId, instructionIds: $instructionIds) {
      ...PartFragment
    }
  }
  ${PART_FRAGMENT}
`;

export const UPDATE_ENDING_INSTRUCTION_ORDER_MUTATIOIN = gql`
  mutation UpdateEndingInstructionOrder($partId: ID!, $instructionIds: [ID!]!) {
    UpdateEndingInstructionOrder(
      partId: $partId
      instructionIds: $instructionIds
    ) {
      ...PartFragment
    }
  }
  ${PART_FRAGMENT}
`;

const useStyles = makeStyles((theme) => ({
  partHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  partContainer: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 2,
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
  },
}));

const ExercisePart: React.FC<Props> = ({
  part,
  index,
  numParts,
  exerciseId,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [dialog, close, ref] = useDialog();

  const [addInstruction] = useMutation<
    AddInstructionToExercisePart,
    AddInstructionToExercisePartVariables
  >(ADD_INSTRUCTION_MUTATION);

  const [addEndingInstruction] = useMutation<
    AddEndingInstructionToExercisePart,
    AddEndingInstructionToExercisePartVariables
  >(ADD_ENDING_INSTRUCTION_MUTATION);

  const [updateInstructionOrder] = useMutation<
    UpdateInstructionOrder,
    UpdateInstructionOrderVariables
  >(UPDATE_INSTRUCTION_ORDER_MUTATIOIN);

  const [updateEndingInstructionOrder] = useMutation<
    UpdateEndingInstructionOrder,
    UpdateEndingInstructionOrderVariables
  >(UPDATE_ENDING_INSTRUCTION_ORDER_MUTATIOIN);

  const onDrop: (params: {
    removedIndex: number | null;
    addedIndex: number | null;
    ending: boolean;
  }) => void = async ({ removedIndex, addedIndex, ending }) => {
    if (removedIndex === null || addedIndex === null) return;
    const reordered = ending
      ? arrayMove(part.endingInstructions, removedIndex, addedIndex)
      : arrayMove(part.instructions, removedIndex, addedIndex);
    try {
      const result = ending
        ? await updateEndingInstructionOrder({
            variables: {
              partId: part.id,
              instructionIds: reordered.map((i) => i.id),
            },
            optimisticResponse: {
              UpdateEndingInstructionOrder: {
                ...part,
                endingInstructions: reordered,
              },
            },
          })
        : await updateInstructionOrder({
            variables: {
              partId: part.id,
              instructionIds: reordered.map((i) => i.id),
            },
            optimisticResponse: {
              UpdateInstructionOrder: {
                ...part,
                instructions: reordered,
              },
            },
          });
      if (result.errors) throw Error(result.errors.toString());
      enqueueSnackbar('Reordered', {
        variant: 'success',
      });
    } catch (e) {
      alert(e);
    }
  };

  const onAddInstruction = async (partId: string, ending: boolean) => {
    dialog({
      title: 'Enter the instruction text',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const text = ref?.current?.value;
            if (!text) throw Error("Couldn't find text");
            close();
            try {
              const response = ending
                ? await addEndingInstruction({
                    variables: { partId, text },
                  })
                : await addInstruction({
                    variables: { partId, text },
                  });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Added Successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Add
        </Button>,
      ],
    });
  };

  return (
    <div>
      <Paper className={classes.partContainer}>
        <div className={classes.partHeader}>
          {numParts > 1 && (
            <ListSubheader>
              Part {index + 1}
              {part.name && ` - ${part.name}`}
            </ListSubheader>
          )}
          <IconButton onClick={() => onAddInstruction(part.id, false)}>
            <Add />
          </IconButton>
          <Recorder path={`instruction-audio/${part.id}.webm`} />
        </div>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={(props) => onDrop({ ...props, ending: false })}
        >
          {part.instructions.map((instruction, instructionIndex) => (
            <Draggable key={instruction.id}>
              <InstructionListItem
                exerciseId={exerciseId}
                index={instructionIndex}
                instruction={instruction}
              />
            </Draggable>
          ))}
        </Container>
      </Paper>

      <Paper className={classes.partContainer}>
        <div className={classes.partHeader}>
          <ListSubheader>To End</ListSubheader>
          <IconButton onClick={() => onAddInstruction(part.id, true)}>
            <Add />
          </IconButton>
          <Recorder path={`instruction-audio/${part.id}-ending.webm`} />
        </div>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={(props) => onDrop({ ...props, ending: true })}
        >
          {part.endingInstructions.map((instruction, instructionIndex) => (
            <Draggable key={instruction.id}>
              <InstructionListItem
                exerciseId={exerciseId}
                index={instructionIndex}
                instruction={instruction}
              />
            </Draggable>
          ))}
        </Container>
      </Paper>
    </div>
  );
};

export default ExercisePart;
