import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import Loading from '../shared/loading';

import {
  MantrasQuery,
  MantrasQuery_PopularMantras,
} from './__generated__/MantrasQuery';

const MANTRAS_QUERY = gql`
  query MantrasQuery {
    PopularMantras {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
}));

const Mantras: React.FC = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<MantrasQuery>(MANTRAS_QUERY);

  const mantras: MantrasQuery_PopularMantras[] =
    data && data.PopularMantras
      ? (data.PopularMantras as MantrasQuery_PopularMantras[])
      : [];

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <Typography variant="h3">Mantras</Typography>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {mantras.map((mantra, index) => (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={mantra.id}
                  component={Link}
                  to={`/mantras/${mantra.id}`}
                  divider
                >
                  <ListItemText primary={mantra.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default Mantras;
