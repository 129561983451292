import React, { useState, useEffect, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Mic from '@material-ui/icons/Mic';
import Stop from '@material-ui/icons/Stop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import firebase from 'firebase';
import CloudDone from '@material-ui/icons/CloudDone';
import CircularProgress from '@material-ui/core/CircularProgress';

// Audio container and codec to record to
const mimeType = 'audio/webm;codecs=pcm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    uploadedIcon: {
      color: '#2ecc71',
    },
  })
);

type Props = {
  path: string;
  // partId: string;
  // ending: boolean;
};

const Recorder: React.FC<Props> = ({ path }) => {
  const classes = useStyles();

  if (!MediaRecorder.isTypeSupported(mimeType)) {
    throw Error(`MIME type "${mimeType}" not supported.`);
  }

  var ref = useMemo(() => firebase.storage().ref(path), [path]);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioData, setAudioData] = useState<Blob | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // Check for an existing recording
  useEffect(() => {
    ref.getDownloadURL().then((url) => setDownloadUrl(url));
  }, [ref]);

  /// When streaming, start recorder
  useEffect(() => {
    if (!stream) return;

    let data: Blob[] = [];

    const rec = new MediaRecorder(stream, {
      mimeType,
    });

    rec.onstop = function (e) {
      const blob = new Blob(data, { type: mimeType });
      setAudioData(blob);
    };

    rec.ondataavailable = function (e) {
      if (e.data.size > 0) {
        data.push(e.data);
      }
    };

    rec.onstart = function (e) {
      data = [];
    };

    rec.start();
  }, [stream]);

  async function startStream() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    setStream(stream);
  }

  function stopStream() {
    if (!stream) return;
    stream.getAudioTracks().forEach((track) => track.stop());
    setStream(null);
  }

  async function uploadRecording() {
    if (!audioData) return;
    const task = ref.put(audioData, { contentType: mimeType });
    task.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        setUploadProgress(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      function (error) {
        // Handle unsuccessful uploads
        setUploadProgress(0);
        alert(error.message);
      },
      async function () {
        // Handle successful uploads on complete
        setUploadProgress(0);
        setDownloadUrl(await task.snapshot.ref.getDownloadURL());
        setAudioData(null);
      }
    );
  }

  return (
    <div className={classes.container}>
      {stream ? (
        <IconButton onClick={stopStream}>
          <Stop />
        </IconButton>
      ) : (
        <IconButton onClick={startStream}>
          <Mic />
        </IconButton>
      )}

      {downloadUrl && !audioData && (
        <>
          <audio src={downloadUrl} controls />
          <IconButton disabled>
            <CloudDone className={classes.uploadedIcon} />
          </IconButton>
        </>
      )}
      {audioData && audioData && (
        <>
          <audio src={URL.createObjectURL(audioData)} controls />
          {uploadProgress === 0 ? (
            <IconButton onClick={uploadRecording}>
              <CloudUpload color="primary" />
            </IconButton>
          ) : (
            <CircularProgress variant="determinate" value={uploadProgress} />
          )}
        </>
      )}
    </div>
  );
};

export default Recorder;
