import React from 'react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { KriyaQuery_Kriya } from './__generated__/KriyaQuery';
import {
  RemoveKriyaTopic,
  RemoveKriyaTopicVariables,
} from './__generated__/RemoveKriyaTopic';
import MergeTopic from './mergeTopic';
import { KRIYA_QUERY } from './kriyaDetails';
import {
  SuggestedKriyaTopics,
  SuggestedKriyaTopicsVariables,
} from './__generated__/SuggestedKriyaTopics';

interface Props {
  kriya: KriyaQuery_Kriya;
}

export const KriyaTopicFragment = gql`
  fragment KriyaTopicFragment on Kriya {
    topicRelations {
      Topic {
        id
        name
      }
      salience
    }
  }
`;

const SUGGESTED_KRIYA_TOPICS = gql`
  query SuggestedKriyaTopics($kriyaId: ID!) {
    Kriya(id: $kriyaId) {
      id
      suggestedTopics
    }
  }
`;

const REMOVE_KRIYA_TOPIC = gql`
  mutation RemoveKriyaTopic($kriyaId: ID!, $topicId: ID!) {
    RemoveKriyaTopic(kriyaId: $kriyaId, topicId: $topicId)
  }
`;

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}));

const KriyaTopics: React.FC<Props> = ({ kriya }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [loadSuggested, suggestedQuery] = useLazyQuery<
    SuggestedKriyaTopics,
    SuggestedKriyaTopicsVariables
  >(SUGGESTED_KRIYA_TOPICS, { variables: { kriyaId: kriya.id } });

  const [removeTopic, { loading }] = useMutation<
    RemoveKriyaTopic,
    RemoveKriyaTopicVariables
  >(REMOVE_KRIYA_TOPIC);

  async function onDelete(topicId: string) {
    const response = await removeTopic({
      variables: {
        kriyaId: kriya.id,
        topicId,
      },
      refetchQueries: [
        { query: KRIYA_QUERY, variables: { kriyaId: kriya.id } },
      ],
    });

    enqueueSnackbar(`${response.data?.RemoveKriyaTopic} removed successfully`, {
      variant: 'success',
    });
  }

  return (
    <div>
      <h3>Topics</h3>
      {kriya.topicRelations.map((relation) => (
        <Chip
          key={relation.Topic?.id}
          label={`${relation.Topic?.name} (${relation.salience})`}
          onDelete={() => onDelete(relation.Topic!.id)}
        />
      ))}
      <div>
        {!suggestedQuery.called && (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => loadSuggested()}
          >
            Load Suggested
          </Button>
        )}
        {suggestedQuery.loading && <CircularProgress />}
        {suggestedQuery.data?.Kriya &&
          (suggestedQuery.data.Kriya[0]!
            .suggestedTopics as any[]).map((suggested, i) => (
            <MergeTopic
              key={i}
              kriya={kriya}
              topicText={suggested.name}
              topicSalience={Math.round(suggested.salience * 100) / 100}
            />
          ))}
      </div>
      <MergeTopic kriya={kriya} />
    </div>
  );
};

export default KriyaTopics;
