import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase';

const httpLink = createHttpLink({
  uri: 'https://api.kundalini.dev',
  // uri: 'http://localhost:4000',
  headers: {
    locale: 'en',
  },
});

const authLink = setContext((_, { headers }) => {
  const currentUser = firebase.auth().currentUser;

  if (!currentUser) return { headers };

  return currentUser.getIdToken().then((token) => {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
