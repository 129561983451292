import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import KriyaImages from '../kriyaImages/kriyaImages';
import Kriyas from '../kriyas/kriyas';
import KriyaDetails from '../kriyaDetails/kriyaDetails';
import Notes from '../notes/notes';
// import SongMantras from '../songMantras/songMantras';
import Mantras from '../mantras/mantras';
import MantraDetails from '../mantraDetails/mantraDetails';
import Publications from '../publications/publications';
import PublicationDetails from '../publicationDetails/publicationDetails';
import Asanas from '../asanas/asanas';
import AsanaDetails from '../asanaDetails/asanaDetails';
import Mudras from '../mudras/mudras';
import MudraDetails from '../mudraDetails/mudraDetails';

import AppContainer from './appContainer';
import Login from './login';
import firebase from 'firebase/app';

type Props = {
  user: firebase.User | null;
};

const ProtectedRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/kriya-images" component={KriyaImages} />
    <Route exact path="/kriyas" component={Kriyas} />
    <Route path="/kriyas/:kriyaId">
      <Route
        path="/kriyas/:kriyaId/:exerciseIndex"
        component={KriyaDetails}
        exact
      />
      <Route path="/kriyas/:kriyaId" component={KriyaDetails} exact />
    </Route>
    <Route exact path="/notes" component={Notes} />
    <Route exact path="/mantras" component={Mantras} />
    <Route exact path="/mantras/:mantraId" component={MantraDetails} />
    <Route exact path="/publications" component={Publications} />
    <Route
      exact
      path="/publications/:publicationId"
      component={PublicationDetails}
    />
    <Route exact path="/asanas" component={Asanas} />
    <Route exact path="/asanas/:asanaId" component={AsanaDetails} />
    <Route exact path="/mudras" component={Mudras} />
    <Route exact path="/mudras/:mudraId" component={MudraDetails} />
    {/* <Route exact path="/song-mantras" component={SongMantras} /> */}

    <Route
      path="*"
      render={(props) => {
        return <h1>Not Found</h1>;
      }}
    />
  </Switch>
);

const Routes: React.FC<Props> = ({ user }) => {
  const admins = [
    'nli91vtaPSc9EHZN42yheUk9k5J3',
    '5nI6LfiWmUVY1pCeK1mHJsp5lxu2',
  ];

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (user ? <Redirect to="/" /> : <Login />)}
        />

        <Route exact path="/403" render={(props) => <h1>Forbidden</h1>} />

        {!user && <Redirect to="/login" />}
        {user && !admins.includes(user.uid) && <Redirect to="403" />}

        <Route
          path="*"
          render={({ location }) => (
            <AppContainer user={user} location={location}>
              <ProtectedRoutes />
            </AppContainer>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
