import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Recorder from '../shared/recorder';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useDialog } from '../navigation/dialogProvider';
import Loading from '../shared/loading';
import {
  MantraDetailsQuery,
  MantraDetailsQueryVariables,
  MantraDetailsQuery_Mantra,
} from './__generated__/MantraDetailsQuery';
import {
  UpdateMantraName,
  UpdateMantraNameVariables,
} from './__generated__/UpdateMantraName';
import {
  UpdateMantraText,
  UpdateMantraTextVariables,
} from './__generated__/UpdateMantraText';
import {
  UpdateMantraOriginalText,
  UpdateMantraOriginalTextVariables,
} from './__generated__/UpdateMantraOriginalText';
import {
  UpdateMantraAliases,
  UpdateMantraAliasesVariables,
} from './__generated__/UpdateMantraAliases';
import {
  UpdateMantraTranslation,
  UpdateMantraTranslationVariables,
} from './__generated__/UpdateMantraTranslation';

const MANTRA_DETAILS_QUERY = gql`
  query MantraDetailsQuery($mantraId: ID!) {
    Mantra(id: $mantraId) {
      id
      name
      text
      originalText
      aliases
      translation
      kriyas(orderBy: name_asc) {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const UPDATE_MANTRA_NAME = gql`
  mutation UpdateMantraName($id: ID!, $name: String!) {
    UpdateMantra(id: $id, name: $name) {
      id
      name
    }
  }
`;

const UPDATE_MANTRA_TEXT = gql`
  mutation UpdateMantraText($id: ID!, $text: String!) {
    UpdateMantra(id: $id, text: $text) {
      id
      text
    }
  }
`;

const UPDATE_MANTRA_ORIGINAL_TEXT = gql`
  mutation UpdateMantraOriginalText($id: ID!, $originalText: String!) {
    UpdateMantra(id: $id, originalText: $originalText) {
      id
      originalText
    }
  }
`;

const UPDATE_MANTRA_ALIASES = gql`
  mutation UpdateMantraAliases($id: ID!, $aliases: [String!]!) {
    UpdateMantra(id: $id, aliases: $aliases) {
      id
      aliases
    }
  }
`;

const UPDATE_MANTRA_TRANSLATION = gql`
  mutation UpdateMantraTranslation($id: ID!, $translation: String!) {
    UpdateMantra(id: $id, translation: $translation) {
      id
      translation
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
    paddingBottom: theme.spacing() * 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentColumn: {
    flex: 1,
    marginTop: theme.spacing() * 4,
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  columnHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing() * 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
  disabled: {
    color: '#95a5a6',
  },
}));

const MantraDetails: React.FC = () => {
  const { mantraId } = useParams();
  const classes = useStyles();
  const [dialog, close, ref] = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useQuery<
    MantraDetailsQuery,
    MantraDetailsQueryVariables
  >(MANTRA_DETAILS_QUERY, { variables: { mantraId } });

  const [updateMantraName] = useMutation<
    UpdateMantraName,
    UpdateMantraNameVariables
  >(UPDATE_MANTRA_NAME);

  const [updateMantraText] = useMutation<
    UpdateMantraText,
    UpdateMantraTextVariables
  >(UPDATE_MANTRA_TEXT);

  const [updateMantraOriginalText] = useMutation<
    UpdateMantraOriginalText,
    UpdateMantraOriginalTextVariables
  >(UPDATE_MANTRA_ORIGINAL_TEXT);

  const [updateMantraAliases] = useMutation<
    UpdateMantraAliases,
    UpdateMantraAliasesVariables
  >(UPDATE_MANTRA_ALIASES);

  const [updateMantraTranslation] = useMutation<
    UpdateMantraTranslation,
    UpdateMantraTranslationVariables
  >(UPDATE_MANTRA_TRANSLATION);

  const mantra: MantraDetailsQuery_Mantra | null =
    data && data.Mantra && data.Mantra.length ? data.Mantra[0] : null;

  if (loading) return <Loading />;

  async function mantraNameDoubleClicked() {
    dialog({
      title: 'Enter the mantra name',
      inputDefaultText: mantra?.name ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const name = ref?.current?.value;
            if (!name) throw Error('No name');
            close();
            try {
              const response = await updateMantraName({
                variables: { id: mantra!.id, name },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMantra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function mantraTextDoubleClicked() {
    dialog({
      title: 'Enter the mantra text',
      inputDefaultText: mantra?.text ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const text = ref?.current?.value;
            if (!text) throw Error('No text');
            close();
            try {
              const response = await updateMantraText({
                variables: { id: mantra!.id, text },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMantra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function mantraOriginalTextDoubleClicked() {
    dialog({
      title: 'Enter the original mantra text',
      inputDefaultText: mantra?.originalText ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const text = ref?.current?.value;
            if (!text) throw Error('No text');
            close();
            try {
              const response = await updateMantraOriginalText({
                variables: { id: mantra!.id, originalText: text },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMantra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function mantraAliasesDoubleClicked() {
    dialog({
      title: 'Enter the mantra aliases',
      inputDefaultText: mantra?.aliases ? mantra.aliases.join(', ') : '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const aliases = ref?.current?.value
              ?.split(',')
              ?.map((alias) => alias.trim());
            if (!aliases) throw Error('No aliases');
            close();
            try {
              const response = await updateMantraAliases({
                variables: { id: mantra!.id, aliases },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMantra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  async function mantraTranslationDoubleClicked() {
    dialog({
      title: 'Enter the mantra translation',
      inputDefaultText: mantra?.translation ?? '',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const translation = ref?.current?.value;
            if (!translation) throw Error('No translation');
            close();
            try {
              const response = await updateMantraTranslation({
                variables: { id: mantra!.id, translation },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateMantra?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3" onDoubleClick={mantraNameDoubleClicked}>
        {mantra?.name}
      </Typography>
      <Typography variant={'caption'}>{mantra?.id}</Typography>
      <div className={classes.row}>
        <div className={classes.contentColumn}>
          <div className={classes.columnHeading}>
            <Typography variant="h4">Text</Typography>
            <Recorder path={`mantra-pronunciations/${mantra?.id}.webm`} />
          </div>
          <div onDoubleClick={mantraTextDoubleClicked}>
            {mantra?.text ? (
              <Typography paragraph>{mantra?.text}</Typography>
            ) : (
              <Typography paragraph className={classes.disabled}>
                Enter mantra text...
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnHeading}>
            <Typography variant="h4">Original Text</Typography>
          </div>
          <div onDoubleClick={mantraOriginalTextDoubleClicked}>
            {mantra?.originalText ? (
              <Typography paragraph>{mantra?.originalText}</Typography>
            ) : (
              <Typography paragraph className={classes.disabled}>
                Enter mantra original text...
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnHeading}>
            <Typography variant="h4">Translation</Typography>
          </div>
          <div onDoubleClick={mantraTranslationDoubleClicked}>
            {mantra?.translation ? (
              <Typography paragraph>{mantra?.translation}</Typography>
            ) : (
              <Typography paragraph className={classes.disabled}>
                Enter mantra translation...
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnHeading}>
            <Typography variant="h4">Aliases</Typography>
          </div>
          <div onDoubleClick={mantraAliasesDoubleClicked}>
            {mantra?.aliases?.length ? (
              <Typography paragraph>{mantra?.aliases.join(', ')}</Typography>
            ) : (
              <Typography paragraph className={classes.disabled}>
                Enter mantra aliases...
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div className={classes.contentColumn}>
        <div className={classes.columnHeading}>
          <Typography variant="h4">
            Kriyas ({mantra?.kriyas?.length ?? 0})
          </Typography>
        </div>
        <List>
          {mantra?.kriyas.map((kriya, index) => (
            <ListItem
              button
              alignItems="flex-start"
              key={kriya.id}
              component={Link}
              to={`/kriyas/${kriya.id}`}
              divider
            >
              <ListItemIcon>
                <img
                  src={kriya.image?.url}
                  className={classes.kriyaImage}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={kriya.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default MantraDetails;
