import React, { useContext, useState, createContext, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

type Options = {
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNodeArray;
  input?: boolean;
  inputDefaultText?: string;
};

const defaultOptions = {
  title: '',
  content: () => null,
  actions: [],
};

const DialogContext = createContext<
  [
    (options: Options) => void,
    () => void,
    React.RefObject<HTMLTextAreaElement>?
  ]
>([() => null, () => null]);

export const useDialog = () => {
  return useContext(DialogContext);
};

const DialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>(defaultOptions);
  const ref = useRef<HTMLTextAreaElement>(null);

  function onDialog(options: Options) {
    setOptions({ ...defaultOptions, ...options });
    setOpen(true);
  }

  function close() {
    setOpen(false);
    if (ref.current) {
      ref.current.value = '';
    }
  }

  return (
    <DialogContext.Provider value={[onDialog, close, ref]}>
      {children}
      <Dialog open={open} onClose={close}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent>
          {options.content}
          {options.input && (
            <TextareaAutosize
              autoFocus
              ref={ref}
              defaultValue={options.inputDefaultText}
              style={{ minWidth: 500, fontSize: '1.5em' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          {options.actions}
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};

export default DialogProvider;
