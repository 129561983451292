import React from 'react';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import { useMutation, gql } from '@apollo/client';
import arrayMove from 'array-move';

import { EXERCISE_QUERY } from './exerciseDetails';
import { useDialog } from '../navigation/dialogProvider';

import {
  ExerciseQuery_Exercise_parts_instructions,
  ExerciseQuery_Exercise_parts_endingInstructions,
} from './__generated__/ExerciseQuery';
import {
  UpdateInstructionText,
  UpdateInstructionTextVariables,
} from './__generated__/UpdateInstructionText';
import {
  DeleteInstruction,
  DeleteInstructionVariables,
} from './__generated__/DeleteInstruction';
import {
  AddImageToInstruction,
  AddImageToInstructionVariables,
} from './__generated__/AddImageToInstruction';
import {
  UpdateInstructionImageOrder,
  UpdateInstructionImageOrderVariables,
} from './__generated__/UpdateInstructionImageOrder';
import {
  RemoveInstructionImage,
  RemoveInstructionImageVariables,
} from './__generated__/RemoveInstructionImage';

interface Props {
  instruction:
    | ExerciseQuery_Exercise_parts_instructions
    | ExerciseQuery_Exercise_parts_endingInstructions;
  index: number;
  exerciseId: string;
}

const useStyles = makeStyles((theme) => ({
  instructionNumber: {
    marginRight: theme.spacing() * 2,
    marginTop: 4,
  },
  kriyaImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
    border: '1px solid grey',
    marginLeft: theme.spacing(),
    pointerEvents: 'none',
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  deleteImageButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    backgroundColor: 'white',
  },
  imageContainer: {
    position: 'relative',
  },
}));

export const INSTRUCTION_FRAGMENT = gql`
  fragment InstructionFragment on Instruction {
    id
    images {
      id
      url
    }
    text
  }
`;

const REMOVE_INSTRUCTION_IMAGE = gql`
  mutation RemoveInstructionImage($instructionId: ID!, $imageId: ID!) {
    RemoveInstructionImage(instructionId: $instructionId, imageId: $imageId) {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

const DELETE_INSTRUCTION_MUTATION = gql`
  mutation DeleteInstruction($instructionId: ID!) {
    DeleteInstruction(id: $instructionId) {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

const UPDATE_INSTRUCTION_MUTATION = gql`
  mutation UpdateInstructionText($instructionId: ID!, $text: String!) {
    UpdateInstructionText(instructionId: $instructionId, text: $text) {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

export const ADD_IMAGE_MUTATION = gql`
  mutation AddImageToInstruction($instructionId: ID!, $imageId: ID!) {
    AddImageToInstruction(instructionId: $instructionId, imageId: $imageId) {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

export const UPDATE_INSTRUCTION_IMAGE_ORDER_MUTATIOIN = gql`
  mutation UpdateInstructionImageOrder($instructionId: ID!, $imageIds: [ID!]!) {
    UpdateInstructionImageOrder(
      instructionId: $instructionId
      imageIds: $imageIds
    ) {
      ...InstructionFragment
    }
  }
  ${INSTRUCTION_FRAGMENT}
`;

const Instruction: React.FC<Props> = ({ instruction, index, exerciseId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, close, ref] = useDialog();

  const [deleteInstruction] = useMutation<
    DeleteInstruction,
    DeleteInstructionVariables
  >(DELETE_INSTRUCTION_MUTATION);

  const [updateText] = useMutation<
    UpdateInstructionText,
    UpdateInstructionTextVariables
  >(UPDATE_INSTRUCTION_MUTATION);

  const [addImage] = useMutation<
    AddImageToInstruction,
    AddImageToInstructionVariables
  >(ADD_IMAGE_MUTATION);

  const [updateInstructionImageOrder] = useMutation<
    UpdateInstructionImageOrder,
    UpdateInstructionImageOrderVariables
  >(UPDATE_INSTRUCTION_IMAGE_ORDER_MUTATIOIN);

  const [removeImage] = useMutation<
    RemoveInstructionImage,
    RemoveInstructionImageVariables
  >(REMOVE_INSTRUCTION_IMAGE);

  const confirmInstructionDelete = async () => {
    dialog({
      title: 'Are you sure?',
      actions: [
        <Button
          color="secondary"
          variant="contained"
          onClick={async () => {
            close();
            try {
              const response = await deleteInstruction({
                variables: { instructionId: instruction.id },
                refetchQueries: [
                  { query: EXERCISE_QUERY, variables: { exerciseId } },
                ],
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Instruction deleted successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Delete
        </Button>,
      ],
    });
  };

  const onDoubleClick = async () => {
    dialog({
      title: 'Enter the instruction text',
      inputDefaultText: instruction.text,
      input: true,
      actions: [
        <Button color="secondary" onClick={confirmInstructionDelete}>
          Delete
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const text = ref?.current?.value;
            if (!text) throw Error('No Text');
            close();
            try {
              const response = await updateText({
                variables: { instructionId: instruction.id, text },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar(
                `${response.data?.UpdateInstructionText?.id} updated successfully`,
                {
                  variant: 'success',
                }
              );
            } catch (e) {
              alert(e);
            }
          }}
        >
          Update
        </Button>,
      ],
    });
  };

  const onDrop: OnDropCallback = async ({ removedIndex, addedIndex }) => {
    if (removedIndex === null || addedIndex === null) return;
    const reordered = arrayMove(instruction.images, removedIndex, addedIndex);
    const imageIds = reordered.map((i) => i.id);
    try {
      const result = await updateInstructionImageOrder({
        variables: {
          instructionId: instruction.id,
          imageIds,
        },
        optimisticResponse: {
          UpdateInstructionImageOrder: {
            ...instruction,
            images: reordered,
          },
        },
      });
      if (result.errors) throw Error(result.errors.toString());
      enqueueSnackbar('Reordered', {
        variant: 'success',
      });
    } catch (e) {
      alert(e);
    }
  };

  const confirmImageDelete = async (imageId: string) => {
    dialog({
      title: 'Are you sure?',
      actions: [
        <Button
          color="secondary"
          variant="contained"
          onClick={async () => {
            close();
            try {
              const response = await removeImage({
                variables: {
                  instructionId: instruction.id,
                  imageId,
                },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Image removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Delete
        </Button>,
      ],
    });
  };

  const onAddImagePressed = () => {
    dialog({
      title: 'Enter image ID',
      input: true,
      actions: [
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const text = ref?.current?.value;
            if (!text) throw Error('No Text');
            close();
            try {
              const response = await addImage({
                variables: { instructionId: instruction.id, imageId: text },
              });
              if (response.errors) throw Error(response.errors.toString());
              enqueueSnackbar('Image added sucessfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        >
          Add
        </Button>,
      ],
    });
  };

  return (
    <React.Fragment>
      <ListItem
        alignItems="flex-start"
        key={instruction.id}
        onDoubleClick={onDoubleClick}
      >
        <div className={classes.row}>
          <div className={classes.instructionNumber}>{index + 1}</div>
          <ListItemText primary={instruction.text} />
        </div>
        <div className={classes.row}>
          <Container lockAxis="x" onDrop={onDrop} orientation="horizontal">
            {instruction.images.map((image, index) => (
              <Draggable key={image.id + index}>
                <div
                  className={classes.imageContainer}
                  onDoubleClick={async (e) => {
                    e.stopPropagation();
                    await copy(image.id);
                    enqueueSnackbar(`${image.id} copied to clipboard`, {
                      variant: 'success',
                    });
                  }}
                >
                  <HighlightOff
                    onClick={() => confirmImageDelete(image.id)}
                    className={classes.deleteImageButton}
                  />
                  <img src={image.url} className={classes.kriyaImage} alt="" />
                </div>
              </Draggable>
            ))}
          </Container>
          <IconButton
            style={{ alignSelf: 'center' }}
            onClick={onAddImagePressed}
          >
            <Add />
          </IconButton>
        </div>

        <ListItemIcon className="drag-handle" style={{ alignSelf: 'center' }}>
          <DragHandleIcon />
        </ListItemIcon>
      </ListItem>
    </React.Fragment>
  );
};

export default Instruction;
