import React from 'react';
import Chip from '@material-ui/core/Chip';
import Hearing from '@material-ui/icons/Hearing';
import Waves from '@material-ui/icons/Waves';
import PanTool from '@material-ui/icons/PanTool';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import { useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ExerciseQuery_Exercise } from './__generated__/ExerciseQuery';
import { RemoveAsana, RemoveAsanaVariables } from './__generated__/RemoveAsana';
import {
  RemoveBreath,
  RemoveBreathVariables,
} from './__generated__/RemoveBreath';
import {
  RemoveEyeFocus,
  RemoveEyeFocusVariables,
} from './__generated__/RemoveEyeFocus';
import {
  RemoveMantra,
  RemoveMantraVariables,
} from './__generated__/RemoveMantra';
import { RemoveMudra, RemoveMudraVariables } from './__generated__/RemoveMudra';
import MetadataSearch from './metadataSearch';

type Props = {
  exercise: ExerciseQuery_Exercise;
};

const REMOVE_ASANA = gql`
  mutation RemoveAsana($from: _ExerciseInput!, $to: _AsanaInput!) {
    RemoveExerciseAsanas(from: $from, to: $to) {
      from {
        id
        asanas {
          id
        }
      }
    }
  }
`;

const REMOVE_BREATH = gql`
  mutation RemoveBreath($from: _ExerciseInput!, $to: _BreathInput!) {
    RemoveExerciseBreaths(from: $from, to: $to) {
      from {
        id
        breaths {
          id
        }
      }
    }
  }
`;

const REMOVE_EYE_FOCUS = gql`
  mutation RemoveEyeFocus($from: _ExerciseInput!, $to: _EyeFocusInput!) {
    RemoveExerciseEyeFocuses(from: $from, to: $to) {
      from {
        id
        eyeFocuses {
          id
        }
      }
    }
  }
`;

const REMOVE_MANTRA = gql`
  mutation RemoveMantra($from: _ExerciseInput!, $to: _MantraInput!) {
    RemoveExerciseMantras(from: $from, to: $to) {
      from {
        id
        mantras {
          id
        }
      }
    }
  }
`;

const REMOVE_MUDRA = gql`
  mutation RemoveMudra($from: _ExerciseInput!, $to: _MudraInput!) {
    RemoveExerciseMudras(from: $from, to: $to) {
      from {
        id
        mudras {
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  metadataItem: {
    marginRight: theme.spacing() * 2,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const ExerciseMetadata: React.FC<Props> = ({ exercise }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [removeAsana] = useMutation<RemoveAsana, RemoveAsanaVariables>(
    REMOVE_ASANA
  );
  const [removeBreath] = useMutation<RemoveBreath, RemoveBreathVariables>(
    REMOVE_BREATH
  );
  const [removeEyeFocus] = useMutation<RemoveEyeFocus, RemoveEyeFocusVariables>(
    REMOVE_EYE_FOCUS
  );
  const [removeMantra] = useMutation<RemoveMantra, RemoveMantraVariables>(
    REMOVE_MANTRA
  );
  const [removeMudra] = useMutation<RemoveMudra, RemoveMudraVariables>(
    REMOVE_MUDRA
  );

  return (
    <>
      {exercise.asanas.map((asana) => (
        <Chip
          key={asana.id}
          className={classes.metadataItem}
          label={asana.name}
          icon={<EmojiPeople />}
          onClick={() => history.push(`/asanas/${asana.id}`)}
          onDelete={async () => {
            try {
              await removeAsana({
                variables: { from: { id: exercise.id }, to: { id: asana.id } },
              });
              enqueueSnackbar('removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        />
      ))}
      {exercise.breaths.map((breath) => (
        <Chip
          key={breath.id}
          className={classes.metadataItem}
          label={breath.name}
          icon={<Waves />}
          onDelete={async () => {
            try {
              await removeBreath({
                variables: { from: { id: exercise.id }, to: { id: breath.id } },
              });
              enqueueSnackbar('removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        />
      ))}
      {exercise.eyeFocuses.map((eyeFocus) => (
        <Chip
          key={eyeFocus.id}
          className={classes.metadataItem}
          label={eyeFocus.name}
          icon={<RemoveRedEye />}
          onDelete={async () => {
            try {
              await removeEyeFocus({
                variables: {
                  from: { id: exercise.id },
                  to: { id: eyeFocus.id },
                },
              });
              enqueueSnackbar('removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        />
      ))}
      {exercise.mantras.map((mantra) => (
        <Chip
          key={mantra.id}
          className={classes.metadataItem}
          label={mantra.name}
          icon={<Hearing />}
          onClick={() => history.push(`/mantras/${mantra.id}`)}
          onDelete={async () => {
            try {
              await removeMantra({
                variables: { from: { id: exercise.id }, to: { id: mantra.id } },
              });
              enqueueSnackbar('removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        />
      ))}
      {exercise.mudras.map((mudra) => (
        <Chip
          key={mudra.id}
          className={classes.metadataItem}
          label={mudra.name}
          icon={<PanTool />}
          onDelete={async () => {
            try {
              await removeMudra({
                variables: { from: { id: exercise.id }, to: { id: mudra.id } },
              });
              enqueueSnackbar('removed successfully', {
                variant: 'success',
              });
            } catch (e) {
              alert(e);
            }
          }}
        />
      ))}
      <MetadataSearch exerciseId={exercise.id} />
    </>
  );
};

export default ExerciseMetadata;
