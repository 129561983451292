import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImageIcon from '@material-ui/icons/Image';
import KriyasIcon from '@material-ui/icons/AccessibilityNew';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HearingIcon from '@material-ui/icons/Hearing';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AsanasIcon from '@material-ui/icons/EmojiPeople';
import NoteIcon from '@material-ui/icons/Note';
import PanToolIcon from '@material-ui/icons/PanTool';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import { Location } from 'history';
import startCase from 'lodash/startCase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Search from '../shared/search';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

type Props = {
  user: firebase.User | null;
  location: Location;
};

const AppContainer: React.FC<Props> = ({ user, location, children }) => {
  const pathnames = location.pathname.split('/').filter((x) => x);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    firebase.auth().signOut();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Kundalini Admin
          </Typography>
          <Search />
          <IconButton color="inherit" component={Link} to="/">
            <DashboardIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/kriya-images">
            <ImageIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/kriyas">
            <KriyasIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/mantras">
            <HearingIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/publications">
            <MenuBookIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/asanas">
            <AsanasIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/notes">
            <NoteIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/mudras">
            <PanToolIcon />
          </IconButton>

          <IconButton
            edge="end"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Avatar src={user?.photoURL ?? ''} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="auth"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Toolbar>
        <Breadcrumbs aria-label="breadcrumb">
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            const label = value
              .split('-')
              .map((part) => startCase(part))
              .join(' ');

            return last ? (
              <Typography color="textPrimary" key={to}>
                {label}
              </Typography>
            ) : (
              <Typography color="inherit" key={to} component={Link} to={to}>
                {label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Toolbar>
      {children}
    </div>
  );
};

export default AppContainer;
