import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';

import Loading from '../shared/loading';

import {
  PublicationsQuery,
  PublicationsQuery_PopularPublications,
} from './__generated__/PublicationsQuery';

const PUBLICATIONS_QUERY = gql`
  query PublicationsQuery {
    PopularPublications {
      id
      name
      imageUrl
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing() * 4,
    paddingRight: theme.spacing() * 4,
  },
  publicationImage: {
    objectFit: 'scale-down',
    width: 100,
    height: 100,
  },
}));

const Publications: React.FC = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<PublicationsQuery>(
    PUBLICATIONS_QUERY
  );

  const publications: PublicationsQuery_PopularPublications[] =
    data && data.PopularPublications
      ? (data.PopularPublications as PublicationsQuery_PopularPublications[])
      : [];

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <Typography variant="h3">Publications</Typography>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <List>
              {publications.map((publication, index) => (
                <ListItem
                  button
                  alignItems="flex-start"
                  key={publication.id}
                  component={Link}
                  to={`/publications/${publication.id}`}
                  divider
                >
                  <ListItemIcon>
                    <img
                      src={publication.imageUrl ?? ''}
                      className={classes.publicationImage}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText primary={publication.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default Publications;
